import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useStations = () => {
  const { wrapper } = useStaticQuery(graphql`
    query {
      wrapper: allGeojsonFeature(filter: {layer: {eq: "stations"}}) {
        stations: nodes {
          properties {
            label
            code
            quantities
            management_unit
            has_daily_quantiles
            sub_management_unit { id, label, order }
            department {
              code
              label
            }
            managers
            pool
            is_spc
            order
            watercourse { id, label }
            thresholds { doe, da, dar, dcr }
          }
          json { geometry }
          vcn3 { d, v }
          vcn10 { d, v }
        }
      }
    }
  `);

  const stations = React.useMemo(
    () => wrapper.stations.map(({ json, properties, ...station }) => ({
      ...station,
      properties: {
        ...properties,
        // set threshold value to null if the first item is null (meaning all of them are)
        thresholds: properties.thresholds.doe ? properties.thresholds : null,
        id: properties.code,
      },
      geometry: JSON.parse(json.geometry),
    })),
    [wrapper],
  );

  return stations;
};

export default useStations;
